


















import { defineComponent } from '@vue/composition-api'
import { ConfettiImage } from '~/components/ConfettiImage.vue'
import { NoSleep } from '~/components/NoSleep.vue'

export default defineComponent({
  components: {
    NoSleep,
    ConfettiImage,
  },
})
