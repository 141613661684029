
import { defineComponent, createElement as h } from '@vue/composition-api'

interface Props {
  lazy?: Boolean
  src: string
}

export const ConfettiImage = defineComponent<Props>({
  props: {
    lazy: {
      type: Boolean,
      default: true,
    },
    src: String,
    load: Function,
  },
  setup(props, { listeners }) {
    return () => {
      const attrs = { src: props.src, draggable: false }
      return props.lazy
        ? h('lazyImg', { attrs, on: listeners })
        : h('img', { attrs })
    }
  },
})

export default ConfettiImage
