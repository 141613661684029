















import { defineComponent, ref } from '@vue/composition-api'

export const NoSleep = defineComponent({
  setup() {
    const loop = ref(false)

    const onLoadedMetadata = ({ target }: Event) => {
      const video = target as HTMLVideoElement
      loop.value = video.currentSrc.includes('.webm')
    }

    // const onTimeUpdate = ({ target }: Event) => {
    //   const video = target as HTMLVideoElement
    //   if (loop.value || video.currentTime < 0.5) return
    //   video.currentTime = Math.random()
    // }

    return {
      onLoadedMetadata,
      loop,
    }
  },
})

export default NoSleep
