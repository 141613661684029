import * as Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser =
  browser.satisfies({
    chrome: '>0',
    safari: '>0',
    // first Chromium-based version
    edge: '>=79',
  }) || false

if (!isValidBrowser) window.showUnsupportedBrowserMessage()