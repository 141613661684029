import VLazyImage from 'v-lazy-image'
import Vue from 'vue'

Vue.use({
  install: (Vue) => {
    Vue.component('lazyImg', {
      ...VLazyImage,
      beforeMount() {
        if (!('IntersectionObserver' in window)) {
          this.intersected = true
          this.loaded = true
          return
        }

        this.observer = new IntersectionObserver((entries) => {
          const image = entries[0]
          if (image.isIntersecting) {
            this.intersected = true
            this.observer.disconnect()
            this.$emit('intersect')
          }
        }, this.intersectionOptions)
      },
      mounted() {
        if (this.observer) this.observer.observe(this.$el)
      },
      destroyed() {
        if (this.observer) this.observer.disconnect()
      },
    })
  },
})
