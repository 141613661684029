import * as FullStory from '@fullstory/browser'
import { Plugin } from '@nuxt/types'
import * as Sentry from '@sentry/browser'
import SentryFullStory from '@sentry/fullstory'
import {
  Dedupe,
  RewriteFrames,
  Vue as VueIntegration,
} from '@sentry/integrations'
import Vue from 'vue'

const AnalyticsPlugin: Plugin = () => {
  const devMode = process.env.NODE_ENV === 'development'
  FullStory.init({ orgId: process.env.FULLSTORY_ORG_ID as string, devMode })
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enabled: !devMode,
    integrations: [
      new RewriteFrames({ root: process.cwd() }),
      new VueIntegration({ Vue, attachProps: true }),
      new SentryFullStory(process.env.SENTRY_ORG_SLUG as string),
      new Dedupe(),
    ],
  })
}

export default AnalyticsPlugin
